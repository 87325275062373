import { QUERIES } from "@/design-system/breakpoints";
import * as RadixTheme from "@radix-ui/themes";
import styled from "styled-components";

export const LayoutContainer = styled(RadixTheme.Container).attrs({
  size: "4",
})`
  padding: 0 90px;
  width: 100%;
  margin: 0 auto;

  @media ${QUERIES.mobile} {
    padding: 16px;
  }
`;
