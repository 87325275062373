"use client"; // Error components must be Client Components

import { ClientError } from "@components/Errors/ClientError";
import { useEffect } from "react";
import MonitoringService from "@lib/MonitoringService";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    MonitoringService.captureException(error);
  }, [error]);

  return <ClientError retry={reset} />;
}
