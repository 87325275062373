"use client";
import { QUERIES } from "@/design-system/breakpoints";
import { LayoutContainer } from "@/design-system/components/LayoutContainer";
import { useTranslations } from "next-intl";
import styled from "styled-components";

function ClientError({ retry }: { retry: () => void }) {
  const t = useTranslations("ErrorBoudary");
  const handleRetry = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    retry();
  };

  return (
    <LayoutContainer>
      <CenterVertically>
        <ErrorHeader>
          <Title>{t("title")}</Title>
          <SubTitle>{t("subtitle")}</SubTitle>
        </ErrorHeader>
        <ErrorBody>
          <div>
            {t.rich("message", {
              retry: (chunks) => (
                <a href="#" onClick={handleRetry}>
                  {chunks}
                </a>
              ),
              mail: (chunks) => (
                <a href="mailto:support@complicated.life">{chunks}</a>
              ),
            })}
          </div>
        </ErrorBody>
      </CenterVertically>
    </LayoutContainer>
  );
}

const CenterVertically = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 48px;
  max-width: 600px;
  margin: auto;

  @media ${QUERIES.mobile} {
    align-items: center;
  }
`;

const ErrorHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled.h1`
  display: inline-block;
  margin: 0 20px 0 0;
  padding-right: 23px;
  font-size: var(--font-size-24);
  font-weight: 500;
  line-height: 49px;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  overflow-wrap: break-word;
`;

const SubTitle = styled.h2`
  font-size: var(--font-size-16);
  font-weight: 400;
  line-height: 49px;
  margin: 0;
  overflow-wrap: break-word;
`;

const ErrorBody = styled.div`
  margin: 24px 0;
  display: inline-block;
  text-align: left;
`;

export { ClientError };
